<template>
    <section class="visitas-index">
        <router-view />
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>